<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="success"
          inline
          title="Gestion des scénarios"
          class="px-5 py-3"
        >
          <v-card-text>
            <v-data-table
              :items="scénarios"
              :headers="headers"
              data-cy="lb-listeScénarios"
            >
              <template v-slot:[`item.nom`]="{ item }">
                <I18nText :text="item.nom" />
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  class="px-2 ml-1 primary"
                  min-width="0"
                  small
                  @click="onEditer(item)"
                >
                  <v-icon small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  v-confirm="{ ok: (dialog) => onEffacer(item) }"
                  class="px-2 ml-1 warning"
                  min-width="0"
                  small
                >
                  <v-icon small> mdi-trash-can-outline </v-icon>
                </v-btn>
              </template>
            </v-data-table>
            <v-btn class="primary" @click="onCréer()">
              Créer un nouveau scénario
            </v-btn>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import restApiService from "@/services/restApiService.js";
import I18nText from "../composants/I18nText.vue";

export default {
  components: { I18nText },
  data: () => ({
    scénarios: [],
    headers: [
      { text: "Identifiant", value: "identifiant" },
      { text: "Nom", value: "nom" },
      { text: "Actions", value: "actions" },
    ],
  }),
  mounted() {
    console.log("mounted");
    this.charger();
  },
  methods: {
    charger() {
      restApiService.get("/api/scenarios").then((result) => {
        this.scénarios = result.data;
      });
    },
    onCréer() {
      this.$dialog
        .prompt(
          {
            title: "Création d'un nouveau scénario",
            body: "Quel est l'indentifiant que vous souhaitez attribuer au scénario",
          },
          {
            promptHelp: "Type in the box below and click '[+:okText]'",
          }
        )
        .then((r) => {
          const infoScénario = {
            identifiant: r.data,
            nom: "",
          };
          restApiService.post("/api/scenarios", infoScénario).then((result) => {
            this.onEditer(result.data);
          });
        });
    },
    onEditer(scénario) {
      this.$router.push({ path: "/ecrans/editionScenario/" + scénario.id });
    },

    onEffacer(item) {
      restApiService.delete(`/api/scenarios/${item.id}`);
      this.charger();
    },
  },
};
</script>
