<template>
  <div>{{ localeText }}</div>
</template>
  
<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  computed: {
    localeText: function () {
      const parser = new DOMParser();

      if (this.text.startsWith("<t>")) {
        const dom = parser.parseFromString(this.text, "application/xml");
        const fr =
          dom.documentElement.getElementsByTagName("fr")[0].textContent;
        const en =
          dom.documentElement.getElementsByTagName("en")[0].textContent;
        return fr;
      }
      return this.text;
    },
  },
};
</script>
